'use client';

import { Button } from '@/components/ui/button';
import store from '@/redux/store';
import { newSessionAction } from '@/utils/mutateSession/fetch';
import { WithLoggedInAuthInfoProps, withRequiredAuthInfo } from '@propelauth/react';

const Error = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) =>
{
	//Try to log the error to Posthog
	const posthog = globalThis.posthog;
	if (posthog)
	{
		const state = store.getState();

		let active_action = null;
		try {
			active_action = state.session.sessionData?.tabs[state.tab.activeTabIndex]?.actions[0];
		}
		catch (e) {
			console.error('Error getting active action', e);
		}

		const emit = {
			error_message: error.message,
			error_digest: error.digest ?? '<none provided>',
			//error,
			user: state.user.user?.email,
			active_action,
			session_id: state.session.sessionData?.id,
		}

		console.log('Emitting fatal error', emit);

		posthog.capture('fatal_error', emit);

		socket.emit('fatal_error', emit);
	}

	return (
		<div className='flex flex-col p-12 w-full h-full'>
			<div className='flex flex-col'>
				<h2 className='text-2xl font-semibold'>Something went wrong!</h2>
				<p className='pb-4'>Sorry about that - we ran into an unrecoverable error.</p>
				<button
					className='w-[320px] mb-6 p-2 bg-maven-primary-400 text-white rounded-md flex items-center justify-center'
					onClick={() =>
					{
						const accessToken = store.getState().user.token;
						newSessionAction(accessToken).then(() =>
						{
							reset();
						});
					}}
				>
					Create New Session
				</button>
				<p className='text-sm text-neutral-600'>If this persists, contact support with the following information:</p>
				<p className='pl-2 text-sm text-neutral-600'>- Error Message: {error.message}</p>
				<p className='pl-2 text-sm text-neutral-600'>- Error Digest: {error.digest ?? '<none provided>'}</p>
			</div>
		</div>
	);
};

export default Error;
